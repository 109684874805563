/* eslint-disable react/no-danger */
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  ProjectPage,
  Paragraph,
  Heading,
  Subheading,
} from "../components/layouts/ProjectPage";
import Separator from "../components/common/Separator";
import {
  Content,
  Learning,
  LearningsList,
  Purpose,
  PurposeParagraph,
  Stack,
  StackExplainer,
  StackRow,
  TechItem,
  TechList,
} from "../components/gaze/styles";

import projects, { IProject } from "../data/projects";

function Gaze(): JSX.Element {
  const logos = useStaticQuery(graphql`
    query {
      monty: file(relativePath: { eq: "logos/monty.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 150
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }

      learnbrite: file(relativePath: { eq: "logos/learnbrite.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 150
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
    }
  `);

  const {
    name,
    sting,
    stack,
    stackExplanation,
    purpose,
    additional,
  }: IProject = projects[1];

  const seo = {
    title: `${name}`,
    description: `${name} was a project that tried to extract meaning from the daily variations of coverage volume for every country in the world`,
  };

  const title = {
    main: name,
    sub: sting,
  };

  const otherProjects = [
    {
      title: "Learnbrite & Mootup",
      url: "/learnbrite",
      thumbnail: logos.learnbrite.childImageSharp.gatsbyImageData,
    },
    {
      title: "The Full Monty",
      url: "/monty",
      thumbnail: logos.monty.childImageSharp.gatsbyImageData,
    },
  ];

  return (
    <ProjectPage seo={seo} title={title} otherProjects={otherProjects}>
      <Content>
        <Purpose>
          <Heading>Purpose</Heading>
          {purpose &&
            purpose.map((paragraph) => (
              <PurposeParagraph key={paragraph.key}>
                <Paragraph dangerouslySetInnerHTML={paragraph} />
              </PurposeParagraph>
            ))}
        </Purpose>
        <Separator />
        <Stack>
          <Heading>The stack</Heading>
          <StackRow>
            <TechList>
              <div>
                {stack &&
                  stack.map((tech: string, index: number) => (
                    <TechItem key={tech} order={index}>
                      {tech}
                    </TechItem>
                  ))}
              </div>
            </TechList>
            <StackExplainer>
              <Paragraph>{stackExplanation}</Paragraph>
            </StackExplainer>
          </StackRow>
        </Stack>
        <Separator isRotated />
        <LearningsList>
          <Heading>What did I actually learn?</Heading>
          {additional.map((learning, index) => (
            <Learning key={learning.key} order={index}>
              <Subheading>{learning.title}</Subheading>
              <Paragraph dangerouslySetInnerHTML={learning} />
            </Learning>
          ))}
        </LearningsList>
      </Content>
    </ProjectPage>
  );
}

export default Gaze;
