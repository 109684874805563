import styled from "styled-components";

export const Content = styled.div`
  display: grid;
  grid-column: 2 / -2;
`;

export const Purpose = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;

  & h2 {
    margin: 0;
  }

  & p {
    max-width: 65ch;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 1024px) {
    & h2 {
      margin-bottom: var(--size-4);
    }

    & div:nth-child(2) {
      margin-left: var(--size-10);
    }

    & div:last-child {
      margin-left: var(--size-3);
    }
  }
`;

export const PurposeParagraph = styled.div`
  & p {
    margin-bottom: var(--size-4);
    margin-top: var(--size-4);
  }
`;

export const Stack = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;

  & > h2 {
    margin: 0;
    text-align: center;
  }

  @media (min-width: 1024px) {
    row-gap: var(--size-4);

    & h2 {
      margin-bottom: var(--size-4);
    }
  }
`;

export const StackRow = styled.div`
  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    column-gap: var(--size-5);
  }
`;

export const TechList = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: var(--size-2) 0;
  padding: 0;
  text-align: center;

  & > div {
    display: flex;
  }

  @media (min-width: 768px) {
    & > div {
      display: flex;
      flex-direction: column;
      row-gap: var(--size-2);
    }
  }

  @media (min-width: 1300px) {
    justify-content: flex-start;
  }
`;

interface ITechItemProps {
  order: number;
}
export const TechItem = styled.li<ITechItemProps>`
  border: 1px solid var(--primary-600);
  border-radius: var(--size-6);
  box-shadow: var(--shadow-xs);
  margin: 0 var(--size-1);
  padding: var(--size-1) var(--size-2);
  transition: box-shadow var(--speed-md) ease-out;

  /* Must replace all transitions to override margin delay */
  transition-delay: 0s, 0s, 0s, 0s, 0s;
  transition-duration: var(--speed-fast), var(--speed-fast), var(--speed-fast),
    var(--speed-fast), var(--speed-fast);
  transition-property: color, font-size, grid-row-gap, margin, padding;
  transition-timing-function: ease-out;

  &:hover {
    box-shadow: var(--shadow-md);
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: 768px) {
    width: 100%;
    margin: 0;
  }

  @media (min-width: 1300px) {
    margin-left: calc(${({ order }) => order} * var(--size-5)) !important;
  }
`;

export const StackExplainer = styled.div`
  max-width: 65ch;

  & p {
    margin: 0;
  }

  @media (min-width: 1300px) {
    padding-left: calc(var(--size-5) * 4);
    padding-bottom: var(--size-2);
  }
`;

export const LearningsList = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;

  & h2 {
    white-space: nowrap;
    margin: 0;
    text-align: center;
  }

  @media (min-width: 1024px) {
    & h2 {
      margin-bottom: var(--size-4);
    }
  }
`;

interface ILearning {
  order: number;
}
export const Learning = styled.div<ILearning>`
  width: fit-content;
  margin-bottom: var(--size-2);
  margin-top: var(--size-6);

  & h3 {
    margin-bottom: var(--size-2);
    text-align: center;
  }

  & p {
    max-width: 65ch;
  }

  @media (min-width: 1024px) {
    margin-bottom: var(--size-4);
    margin-top: var(--size-6);

    &:nth-child(2) {
      margin-left: calc(var(--size-10) * -1);
    }

    &:last-child {
      margin-left: var(--size-10);
    }
  }
`;
